import { Box, Typography, Grid2 as Grid, useMediaQuery, Chip } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

import { CabinPricingOptions } from "../molecules/CabinPricingOptions";
import { CruiseDurationAndDestination } from "../molecules/CruiseDurationAndDestination";

export function CruiseOverview() {
  const { cruise } = useTypedSelector((state) => state.search);

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const mktTag = useMemo(() => {
    const mktTags = cruise?.cruise?.tags?.find((tag) => tag.type === "MKT");

    return mktTags;
  }, [cruise?.cruise?.tags]);

  const specialOffer = useMemo(
    () => cruise?.cruise?.descriptions?.find((desc) => desc.category === "Highlights"),
    [cruise?.cruise?.descriptions],
  );

  return (
    <Box
      sx={{
        py: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid spacing={4} container>
        {cruise?.cruise.map && (
          <Grid size={{ xs: 12, md: 6 }}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: {
                  xs: 270,
                  md: 400,
                  lg: 620,
                },
                borderRadius: (theme) => theme.toPx(theme.customShape.smallRadius),
                overflow: "hidden",
              }}
            >
              <img
                src={cruise?.cruise.map}
                alt="ship"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
          </Grid>
        )}

        <Grid size={{ xs: 12, md: 6 }}>
          {mktTag && <Chip label={mktTag.name} color="error" sx={{ mb: 2 }} />}

          <Typography component="h1" variant="heroTitle" color="text-dark">
            {cruise?.cruise.name}
          </Typography>

          {specialOffer && (
            <Typography
              component="p"
              variant="smallHeader"
              color="text-primary"
              sx={{ mt: 2, display: "block" }}
            >
              {specialOffer.title}
            </Typography>
          )}

          {isDesktop && <CruiseDurationAndDestination />}
        </Grid>
      </Grid>

      {!isDesktop && <CruiseDurationAndDestination />}

      <CabinPricingOptions />
    </Box>
  );
}
