import { createSlice } from "@reduxjs/toolkit";

import { SearchAPI } from "../services/SearchService";

export interface Market {
  gft: string;
  extra: string;
  price: string;
  single: string;
}

export interface Grade {
  code: string;
  colour_code: string;
  decks: Array<{ code: string; name: string }>;
  descriptions: Array<{ category: string; description: string; title: string }>;
  images: string[];
  maximum_occupancy: number;
  meta_category: string;
  minimum_occupancy: number;
  name: string;
  size: string;
  price?: string;
  single: number;
  double: number;
  triple: number;
  quadruple: number;
}

export interface Deck {
  code: string;
  name: string;
  images: string[];
}

export interface Prices {
  child: string;
  extra: string;
  fourth: string;
  gft: string;
  grade: string;
  infant: string;
  ncf: string;
  single: string;
  standard: string;
  third: string;
}

export interface Price {
  currency: string;
  market: string;
  rate_code: string;
  rate_name: string;
  rate_restriction: string;
  rate_description: string[];
  prices: Prices[];
  lowest_fare: boolean;
}

export interface Brand {
  code: string;
  description: Array<{ category: string; description: string; title: string }>;
  images: string[];
  logo: string;
  name: string;
}

export interface Tag {
  name: string;
  type: string;
}

export interface ICruise {
  source: string;
  embark: string;
  code: string;
  valid_from: string | null;
  valid_to: string | null;
  ship: {
    code: string;
    name: string;
    images: string[];
    grades: Grade[];
    decks: Deck[];
    brand: Brand;
    total_passengers: number;
    descriptions: Array<{ category: string; description: string; title: string }>;
  };
  cruise: {
    code: string;
    name: string;
    nights: number;
    images: string[];
    map: string;
    descriptions: Array<{ category: string; description: string; title: string }>;
    itinerary: Array<{
      day: number;
      port: string;
      tender: boolean;
      country: string;
      description: string;
      arrive_time: string | null;
      depart_time: string | null;
      extra_information: null;
      images: Array<{
        image: string;
        primary: boolean;
        size: string;
        title: string;
      }>;
    }>;
    tags: Tag[] | null;
    countries: string[];
  };
  markets: {
    suite: Market;
    inside: Market;
    balcony: Market;
    outside: Market;
    currency: string;
  };
  pricing: Price[];
}

export interface SearchState {
  searchParams: {
    start_date?: string;
    end_date?: string;
    tag?: string;
    night?: string;
  };
  results: ICruise[] | undefined;
  cruise: ICruise | undefined;
  isCruiseLoading: boolean;
  isLoading: boolean;
  error: boolean;
}

const initialState: SearchState = {
  searchParams: {},
  results: undefined,
  cruise: undefined,
  isCruiseLoading: true,
  isLoading: true,
  error: false,
};

const filterResultsByPrice = (results: ICruise[] | undefined) => {
  if (!results) {
    return [];
  }

  return results.filter((cruise) => {
    return Object.values(cruise.markets)
      .filter((market): market is Market => {
        const isMarketValid = Boolean(market) && typeof market === "object" && !!market.price;

        return isMarketValid;
      })
      .some((market) => {
        return Number(market.price) > 0;
      });
  });
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(SearchAPI.endpoints.initSearchResults.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(SearchAPI.endpoints.initSearchResults.matchFulfilled, (state, action) => {
        const filteredResults = filterResultsByPrice(action.payload);

        state.results = filteredResults;
        state.isLoading = false;
      })
      .addMatcher(SearchAPI.endpoints.initSearchResults.matchRejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addMatcher(SearchAPI.endpoints.initCruise.matchPending, (state) => {
        state.isCruiseLoading = true;
      })
      .addMatcher(SearchAPI.endpoints.initCruise.matchFulfilled, (state, action) => {
        state.cruise = action.payload;
        state.isCruiseLoading = false;
      })
      .addMatcher(SearchAPI.endpoints.initCruise.matchRejected, (state) => {
        state.isCruiseLoading = false;
        state.error = true;
      });
  },
});

export default searchSlice.reducer;
